<template>
  <svg 
    class="flag"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4925_1278)">
      <path d="M24 0H0V18H24V0Z" fill="#ED2939"/>
      <path d="M16 0H0V18H16V0Z" fill="white"/>
      <path d="M8 0H0V18H8V0Z" fill="#002395"/>
    </g>
    <defs>
      <clipPath id="clip0_4925_1278">
        <rect width="24" height="18" rx="2" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
.flag {
  border-radius: 2px;
}
</style>
