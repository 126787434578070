<!-- eslint-disable max-len -->
<template>
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="flag"
  >
    <g clip-path="url(#clip0_36_92223)">
      <path d="M0 0H24V18H0V0Z" fill="#21468B" />
      <path d="M0 0H24V12H0V0Z" fill="white" />
      <path d="M0 0H24V6H0V0Z" fill="#AE1C28" />
    </g>
    <defs>
      <clipPath id="clip0_36_92223">
        <rect width="24" height="18" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
.flag {
  border-radius: 2px;
}
</style>
